@import "ol/ol.css";

html {
    padding: 0;
    margin: 0;
}

body { 
    min-height: 100vh; 
    padding: 0;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #111;
    /*background-image: linear-gradient(-45deg, #333, #111);*/
    /*background-image: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%)*/
    background-image: radial-gradient(ellipse at bottom, #141b14 0%, #080808 90%)
    
}

.section-title {
    display: block;
    color: #e3e3e3;
    text-align: center;
    padding: 52px 26px 26px 26px;
    text-decoration: none;
    font-size: 16px;
    font-family: Sans-Serif;
}

.section-subtitle {
    display: block;
    color: #00ff00;
    text-align: center;
    padding: 52px 26px 26px 26px;
    text-decoration: none;
    font-size: 18px;
    font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
}
